<template>
  <div class="container">
    <img class="main-image" src="@/assets/images/1.png" alt="" />
    <div class="block">
      <div class="title">
        {{ $t("debtRelief.debtReductionReachesTheStandardAmount") }}
      </div>
      <div class="value">{{ totalmoney }}</div>
    </div>
    <div class="block">
      <div class="title">
        {{ $t("debtRelief.numberOfDirectPushPersonnel") }}
      </div>
      <div class="value">{{ count }}</div>
    </div>
  </div>
</template>

<script>
import { getzhaiwu } from "@/request/api";
export default {
  data() {
    return {
      count: 0,
      totalmoney: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      getzhaiwu().then((res) => {
        if (res.code == 200) {
          const { count, totalmoney } = res.data;
          this.count = count;
          this.totalmoney = totalmoney;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main-image {
  width: 480px;
  display: block;
  margin: 20px auto 56px;
}
.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
  .title {
    font-size: 40px;
    margin-bottom: 10px;
  }
  .value {
    font-size: 40px;
    font-weight: bold;
  }
}
</style>
